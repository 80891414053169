/* Site colors, font sizes, etc. are defined here. */
@import "./src/theme/variables.scss";

/* ==============================================================================
    Import bootstrap styles within class to isolate css impact when components
    are imbedded in other sites.
================================================================================= */
html {
    line-height: 1.15;
}

body {
    margin: 0;
    font-family: "acumin-pro-condensed";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* Site built on Bootstrap */
    @import "~bootstrap/scss/bootstrap";

    .bg-orange {
        background-color: $color-orange;
    }
    .bg-orange-dark {
        background-color: $color-orange-dark;
    }
    .bg-orange-darker {
        background-color: $color-orange-darker;
    }
    .bg-orange-darkest {
        background-color: $color-orange-darkest;
    }
    .bg-orange-light {
        background-color: $color-orange-light;
    }
    .bg-orange-lighter {
        background-color: $color-orange-lighter;
    }
    .bg-orange-lightest {
        background-color: $color-orange-lightest;
    }

    .text-orange {
        color: $color-orange;
    }

    .underlined {
        text-decoration: underline;
        color: #0056b3;
    }

    .non-underlined,
    .non-underlined:hover {
        text-decoration: none;
        color: black;
    }

    .header-color-bar {
        height: 2rem;
    }

    .footer-container {
        padding: 2rem !important;
    }

    .footer-colored-text {
        font-size: 24px;
    }

    .footer-copyright-text {
        padding-top: 2rem;
    }

    .header-container {
        padding: 2rem 0;
    }

    .menu-image {
        padding: 0 2rem;
    }

    ul.navbar-nav > li > a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.25rem;
        cursor: pointer;
        border-width: 0px;
        border-bottom-width: 2px;
        border-color: transparent;
        padding: 0 !important;
        border-style: solid;
        width: max-content;
        color: black !important;
    }

    ul.navbar-nav > li > a.active {
        border-color: #f5a81c;
        color: rgba(45, 42, 38, 0.6) !important;
    }

    .app-button {
        width: 256px;
        height: 52px;
        background-color: #f5a81c;
        border-radius: 9999px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 700;
        color: #2d2a26;
        cursor: pointer;
        text-align: center;
    }

    .app-button:hover {
        color: #2d2a26;
        cursor: pointer;
        text-align: center;
        border-bottom: transparent;
        text-decoration: none;
    }

    #floating-img {
        z-index: 3;
    }

    #floating-img-container {
        top: 0;
        display: flex;
        background-color: #0003;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 2;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
    }

    .quiz-image {
        max-width: 400px;
        margin-bottom: 1rem;
    }

    .selected-answer {
        border-width: 0;
        padding-bottom: 1px;
        color: #b8c2cc;
        border-bottom-width: 1px;
        border-color: #f5a81c;
        border-style: solid;
    }

    .input-fields {
        width: 16rem;
        height: 2rem;
        border-width: 0;
        padding-bottom: 1px;
        border-bottom-width: 1px;
        border-color: #f5a81c;
        border-style: solid;
        border-radius: 0%;
    }

    .input-fields::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: 700;
        color: #0005;
    }

    .input-fields::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: 700;
        color: #0005;
    }

    .input-fields:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: 700;
        color: #0005;
    }

    .input-fields:-moz-placeholder {
        /* Firefox 18- */
        font-weight: 700;
        color: #0005;
    }

    .max-w-lg {
        max-width: 50rem;
    }

    .max-w-md {
        max-width: 38rem;
    }

    .max-w-sm {
        max-width: 21rem;
    }

    .ch5 {
        font-size: 0.875rem;
    }

    .ch4 {
        font-size: 1rem;
    }

    .ch3 {
        font-size: 1.125rem;
    }

    .ch2 {
        font-size: 1.5rem;
    }

    .ch1 {
        font-size: 1.875rem;
    }

    .cp-5 {
        padding: 2rem;
    }

    .cmt-5 {
        margin-top: 2rem;
    }

    .cmb-5 {
        margin-bottom: 2rem;
    }

    .w-8 {
        width: 2rem;
    }

    .h-0 {
        height: 0px;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        right: 0;
    }
    .h-8 {
        height: 2rem;
    }

    .br-5 {
        border-radius: 5px;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .mail-box {
        display: flex;
        height: 100%;
    }

    .fab-mail {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border-radius: 25px;
        z-index: 3;
        height: 50px;
        width: 130px;
        cursor: pointer;
    }

    .mail-modal-bg {
        position: fixed;
        top: 0;
        background-color: #0008;
        z-index: 2;
    }

    .toast-message {
        z-index: 10;
        position: fixed;
        bottom: 5%;
        left: 0;
    }

    /* Small devices */
    @media screen and (max-width: 992px) {
        #navbarList {
            background-color: rgb(184, 194, 204);
            padding: 1rem;
        }
        .menu-image {
            width: 16rem;
        }
    }

    @media screen and (max-width: 575px) {
        .mobile {
            display: block;
        }
        .desktop {
            display: none;
        }

        .mail-box-mobile {
            flex-direction: column;
        }

        #floating-img{
            max-width : 95%
        }
    }

    /* Larger devices  */
    @media screen and (min-width: 575px) {
        .mobile {
            display: none;
        }
        .desktop {
            display: block;
        }
        .mail-box-desktop {
            flex-direction: row;
        }
    }

    @media screen and (min-width: 992px) {
        #navbarList {
            margin-left: 8rem;
        }
        .menu-image {
            width: 320px;
        }
    }

    .hak-avatar-img {
        max-width: 2rem !important;
        height: 2rem !important;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: top;
    }

    .hak-emaillist-avatar {
        flex: inherit;
        display: flex;
        width: 59px;
    }

    .wistia_responsive_padding_style {
        padding: 56.25% 0 0 0;
        position: relative;
    }

    .wistia_responsive_wrapper_style {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .wistia_embed_style {
        height: 100%;
        position: relative;
        width: 100%;
    }

    .wistia_swatch_style {
        height: 100%;
        left: 0;
        opacity: 1;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: opacity 200ms;
        width: 100%;
    }

    .wistia_swatch_img_style {
        filter: blur(5px);
        height: 100%;
        object-fit: contain;
        width: 100%;
    }

    .hak-read {
        height: 8px;
        width: 8px;
        border-radius: 4px;
        background-color: transparent;
    
    }

    .hak-unread{
        display: block;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        background-color: lightseagreen;
    }
}
